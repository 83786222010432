<template>
  <mobile-screen
    :header="true"
    screen-class="qr-codes-filter-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName || 'r_qr-codes',
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("utilities", "qr-codes", "qr-codes-filter") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <section class="filters-content">
      <ul class="filter-sections" v-if="sections && sections.length">
        <li class="filter-section" v-for="section in sections" :key="section">
          <button
            class="select-filter-section-btn"
            :class="{ active: section === selectedSection }"
            @click="selectSection(section)"
          >
            {{
              displayLabelName("utilities", "qr-codes", section.toLowerCase())
            }}
          </button>
        </li>
      </ul>
      <nav class="filters-menu">
        <ul
          class="filters-menu-options-list"
          v-if="menuOptions && menuOptions.length"
        >
          <li
            class="filters-menu-option"
            v-for="option in menuOptions"
            :key="option.id"
            @click="selectFilter(selectedSection, option.id)"
          >
            <div
              class="filters-menu-option-btn"
              :class="{
                active: selectedOptionInList(option.id)
              }"
            >
              <!-- Type -->
              <span v-if="selectedSection === 'Type'">{{
                displayLabelName("utilities", "qr-codes", option.name)
              }}</span>
              <!-- Level -->
              <span v-else>{{ option.name }}</span>
            </div>
            <div
              class="filters-full-path"
              v-if="
                selectedSection === 'Levels' &&
                  option.full_path &&
                  option.full_path.length
              "
            >
              ({{ option.full_path.join(", ") }})
            </div>
          </li>
        </ul>
      </nav>
    </section>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button class="action-btn" @click="clearFilters">
              {{ displayLabelName("search.filters.clear-all") }}
            </button>
          </li>
          <li class="action">
            <button class="action-btn" @click="triggerSearch">
              {{ displayLabelName("search.filters.apply") }}
            </button>
          </li>
        </ul>
      </nav>
    </template>
  </mobile-screen>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";

export default {
  name: "QrCodesFilter",
  computed: {
    ...mapState("level", ["levels"]),
    ...mapState("qrCodeFilters", [
      "sections",
      "typesOptions",
      "selectedSection",
      "selectedTypeId",
      "selectedLevelId"
    ]),
    menuOptions() {
      if (this.selectedSection === "Type") {
        return this.typesOptions;
      } else if (this.selectedSection === "Levels") {
        return this.levels && this.levels.data;
      } else {
        return null;
      }
    }
  },
  created() {
    if (this.selectedSection === "Levels") {
      if (!this.levels) {
        const params = {
          depth: "BOTTOM",
          includes: ["full_path"]
        };
        this.getLevels(params);
      }
    }
  },
  methods: {
    ...mapActions("level", ["getLevels"]),
    ...mapActions("qrCodeFilters", [
      "setSelectedSection",
      "setSelectedTypeId",
      "setSelectedLevelId",
      "clearAllFilters",
      "activateSearch"
    ]),
    ...mapActions("pagination", ["setPaginationData"]),
    selectSection(section) {
      if (section !== this.selectedSection) {
        this.setSelectedSection(section);

        if (section === "Levels" && !this.levels) {
          const params = {
            depth: "BOTTOM",
            includes: ["full_path"]
          };
          this.getLevels(params);
        }
      }
    },
    selectFilter(type, id) {
      if (type === "Type") {
        if (id === this.selectedTypeId) {
          this.setSelectedTypeId(null);
        } else {
          this.setSelectedTypeId(id);
        }
      } else if (type === "Levels") {
        if (id === this.selectedLevelId) {
          this.setSelectedLevelId(null);
        } else {
          this.setSelectedLevelId(id);
        }
      }
    },
    selectedOptionInList(id) {
      if (this.selectedSection === "Type") {
        return this.selectedTypeId === id;
      } else if (this.selectedSection === "Levels") {
        return this.selectedLevelId === id;
      }
    },
    clearFilters() {
      this.setPaginationData(null);
      this.clearAllFilters();
      this.activateSearch(true);
    },
    triggerSearch() {
      this.setPaginationData(null);
      this.activateSearch(true);
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: false
    }
  }
};
</script>
